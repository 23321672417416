const aliasAction = (action) => {
  switch (action) {
    case "create":
      return "new";
    case "update":
      return "edit";
    default:
      return action;
  }
};

const runComponentPackages = () => {
  const components = [...document.querySelectorAll("[data-component]")];
  const packages = [...new Set(components.map((component) => component.dataset.component))];

  for (const path of packages) {
    const dependents = components.filter((component) => component.dataset.component === path);

    import(`@components/${path}`)
      .then(({ default: pkg }) => {
        for (const dependent of dependents) {
          pkg(dependent);
        }
      })
      .catch(silenceRouteFailure);
  }
};

const runControllerPackage = () => {
  const controller = document.querySelector("[data-controller]").dataset.controller;
  const action = document.querySelector("[data-action]").dataset.action;

  import(`@controllers/${controller}/${aliasAction(action)}`)
    .then(({ default: pkg }) => {
      pkg();
    })
    .catch(silenceRouteFailure);
};

const silenceRouteFailure = (error) => {
  if (/^Cannot find module/.test(error.message)) {
    console.log(error);
  } else {
    throw error;
  }
};

export { runComponentPackages, runControllerPackage };
